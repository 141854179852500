// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-individual-js": () => import("./../../../src/templates/blog-individual.js" /* webpackChunkName: "component---src-templates-blog-individual-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-previews-js": () => import("./../../../src/templates/previews.js" /* webpackChunkName: "component---src-templates-previews-js" */),
  "component---src-templates-work-index-js": () => import("./../../../src/templates/work-index.js" /* webpackChunkName: "component---src-templates-work-index-js" */),
  "component---src-templates-work-individual-js": () => import("./../../../src/templates/work-individual.js" /* webpackChunkName: "component---src-templates-work-individual-js" */)
}

